import type { Status, InstallationTerminStage } from "@/types";

export const statusList: Array<Status> = [
  {
    id: "e2f2089f-73c7-4658-b9bd-8c2c139e11af",
    color: "#A8C5DA",
    name: "Offer Received",
  },
  {
    id: "YqJV",
    color: "#A8C5DA",
    name: "Technical Planning",
  },
  {
    id: "47b494ce-3ca6-4b09-a8a6-88dd68443236",
    color: "#B1E3FF",
    name: "Project Planning",
  },
  {
    id: "L=IY",
    color: "#B1E3FF",
    name: "Installation",
  },
  {
    id: "LE<c",
    color: "#A1E3CB",
    name: "Post Installation",
  },
  {
    id: "syba",
    color: "#A1E3CB",
    name: "Invoice",
  },
  {
    id: "4ccb87e9-0f6e-495f-8f44-3b9ea2e6bda3",
    color: "#A1E3CB",
    name: "Done",
  },
  {
    id: "<htm",
    color: "#E9827D",
    name: "Cancelled",
  },
];

export const installationTerminStageList: Array<InstallationTerminStage> = [
  {
    name: "Open",
    color: "#e3e2e0",
  },
  {
    name: "Proposed",
    color: "#e3e2e0",
  },
  {
    name: "Confirmed",
    color: "#d3e5ef",
  },
  {
    name: "Completed",
    color: "#dbeddb",
  },
  {
    name: "Not Completed",
    color: "#f9e4bc",
  },
  {
    name: "Failed smalt",
    color: "#fadec9",
  },
  {
    name: "Failed Customer",
    color: "#ffe2dd",
  },
  {
    name: "Failed external",
    color: "#f5e0e9",
  },
];
